import React, { useState, useEffect } from 'react'
import { Router } from '@reach/router'
import { graphql, navigate } from 'gatsby'

import DraftList from '../components/drafts/draftList'
import DraftPage from '../components/drafts/draftPage'

const showDrafts = !!process.env.GATSBY_WORDPRESS_JWT_USERNAME && !!process.env.GATSBY_WORDPRESS_JWT_PASSWORD

const DraftsPage = props => {
    const [state, setState] = useState({
        accessToken: null,
        isFetching: true
    })

    if (!showDrafts) {
        if (typeof window !== 'undefined') {
            navigate('404')
        }
        return <></>
    }

    useEffect(() => {
        fetch(
            `${process.env.GATSBY_WORDPRESS_DOMAIN}/wp-json/jwt-auth/v1/token`,
            {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: process.env.GATSBY_WORDPRESS_JWT_USERNAME,
                    password: process.env.GATSBY_WORDPRESS_JWT_PASSWORD
                })
            }
        )
            .then(res => res.json())
            .then(data => {
                setState({ accessToken: data.token, isFetching: false })
            })
            .catch(error => {
                setState({ accessToken: null, isFetching: false })
                !!window &&
                    !!window.appInsights &&
                    window.appInsights.trackException({ error: 'Drafts request failed', properties: { componentStack: JSON.stringify(error) } })
            })
    }, [])

    return (
        <>
            <Router basepath='drafts'>
                <DraftList path='/' accessToken={state.accessToken} isParentFetching={state.isFetching} parentProps={props} />
                <DraftPage
                    path='page/:contentId'
                    accessToken={state.accessToken}
                    type='page'
                    isParentFetching={state.isFetching}
                    data={props.data}
                    pageContext={props.pageContext}
                />
                <DraftPage
                    path='post/:contentId'
                    accessToken={state.accessToken}
                    type='post'
                    isParentFetching={state.isFetching}
                    data={props.data}
                    pageContext={props.pageContext}
                />
            </Router>
        </>
    )
}

export const pageQuery = graphql`
    query DraftsQuery {
        socialImage: file(relativePath: { regex: "/Social/" }) {
            publicURL
        }
        site {
            siteMetadata {
                socialAlt
                siteUrl
            }
        }
    }
`

export default DraftsPage