import React, { useState } from 'react'
import range from 'lodash/range'
import Box from '@material-ui/core/Box'
import Skeleton from '@material-ui/lab/Skeleton'

import LayoutContent from '../layout/layoutContent'
import Link from '../common/link'
import CmsHeader from './cmsHeader'

export default ({ accessToken, isParentFetching, parentProps }) => {
    const [postState, setPostState] = useState({ postList: null, isFetchingPosts: true })
    const [pageState, setPageState] = useState({ pageList: null, isFetchingPages: true })

    async function getPagesAndPosts(accessToken, reqList) {
        try {
            var data = await Promise.all(
                reqList.map(({ url, type }) =>
                    fetch(url, {
                        method: 'get',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${accessToken}`
                        }
                    })
                        .then(res => res.json())
                        .then(data => {
                            if (type === 'post') {
                                setPostState({ postList: data, isFetchingPosts: false })
                            } else if (type === 'page') {
                                setPageState({ pageList: data, isFetchingPages: false })
                            }
                        })
                )
            )

            return data
        } catch (error) {
            setPostState({ postList: null, isFetchingPosts: false })
            setPageState({ pageList: null, isFetchingPages: false })
            !!window &&
                !!window.appInsights &&
                window.appInsights.trackException({
                    error: 'Drafts request failed',
                    properties: { componentStack: JSON.stringify(error) }
                })
        }
    }

    if (!!accessToken && !isParentFetching && postState.isFetchingPosts && pageState.isFetchingPages) {
        getPagesAndPosts(accessToken, [
            { url: `${process.env.GATSBY_WORDPRESS_DOMAIN}/wp-json/wp/v2/posts?status=draft`, type: 'post' },
            { url: `${process.env.GATSBY_WORDPRESS_DOMAIN}/wp-json/wp/v2/pages?status=draft`, type: 'page' }
        ])
    }

    return (
        <>
            <CmsHeader />
            <LayoutContent {...parentProps} setBackgroundImage={true}>
                <Box pt={3} pb={4}>
                    <h1 className='h2'> Draft Post List </h1>
                    {postState.isFetchingPosts || isParentFetching
                        ? range(3).map(i => <Skeleton key={i} width='25%' />)
                        : postState.postList.map((child, i) => {
                              return (
                                  <Box key={i} pl={8}>
                                      <Link to={`/drafts/post/${child.id}`}>{child.title.rendered ? child.title.rendered : 'undefined'}</Link>
                                  </Box>
                              )
                          })}

                    <h1 className='h2'> Draft Page List </h1>
                    {pageState.isFetchingPages || isParentFetching
                        ? range(3).map(i => <Skeleton key={i} width='25%' />)
                        : pageState.pageList.map((child, i) => {
                              return (
                                  <Box key={i} pl={8}>
                                      <Link to={`/drafts/page/${child.id}`}>{child.title.rendered ? child.title.rendered : 'undefined'}</Link>
                                  </Box>
                              )
                          })}
                </Box>
            </LayoutContent>
        </>
    )
}
